import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image";
import {Row, Col, Container} from "react-bootstrap";
import blogStyles from "./blog.module.css";
import globalStyles from "../styles/global.module.css"

const VlogsPage =  ({data, author}) => {
  const authorPosts = author ? data.allMarkdownRemark.edges.filter(post => post.node.frontmatter.author === author ) : data.allMarkdownRemark.edges;

    return (
      <Layout className="site-content">
        <Container>
          <Row>
            <Col>
              <h1 className={globalStyles.entryTitle}>MOMS Vlogs</h1>
              {authorPosts.map(({node}) => (
                <Row key={node.id } className={blogStyles.blogSection}>
                  <Col md={3}>
                    <div className={blogStyles.postThumbnail}>
                      <Link to={node.frontmatter.path} >
                        <Img  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                      </Link>
                    </div>
                  </Col>
                  <Col md={9}>
                    <div className="blog-post-content">
                      <div className="entry-meta">
                        <Link to={node.frontmatter.path}>
                          <h1 className={blogStyles.blogTitle}>{node.frontmatter.title}</h1>
                        </Link>
                        <Link to={node.frontmatter.path}>
                          <span >{node.frontmatter.date }</span>
                        </Link>
                        <Link className="link-to-page"  to={"/author/" + node.frontmatter.author}>
                          <span>{node.frontmatter.author}</span>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    allMarkdownRemark (
        filter: {frontmatter: {posttype: {eq: "vlog"}}},
        sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            author
            date(formatString: "DD MMMM, YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          html
        }
      }
    }
  }
`;

export default VlogsPage
